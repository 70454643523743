import { View } from 'react-native';
import ImageList from '../components/ImageList';
import React, { useState, useEffect, useContext } from 'react';
import AreaContestSelect from '../components/AreaContestSelect';
import SideScroll from '../stories/components/Scroll/SideScroll';
import UserNameBar from '../stories/components/Bar/UserNameBar';
import axios from '../axios/axios';
import { AuthContext } from '../contexts/Auth';
import { Spinner } from 'native-base';
import { GlobalContext } from '../contexts/Global';
import { usePrevious } from '../utils/utils';
import { useLocation, useNavigate, useOutletContext, useParams, useSearchParams } from '../router/react-router';
import { useThemeColors } from '../components/Themed';
import { ColorNames } from '../types';
import PostsDetailModal from './PostsDetailModal';

const retrieveUrlLastSection = (url: string): string => {
    const result = url.match(/([^/.]+)/g);
    return result && result[result.length - 1] ? result[result.length - 1] : '';
};

export default function PostsScreen() {
    const { goToTop } = useOutletContext<any>();
    const Colors: ColorNames = useThemeColors();
    const location = useLocation();
    const postId = retrieveUrlLastSection(location.pathname);
    const [searchSettings, setSearchSettings] = useState<any>(null);
    const [selected, setSelected] = useState(0);
    const { currentUser } = useContext(AuthContext);
    const {
        areaId,
        keyword,
        setKeyword,
        setInputWord,
        setSearchModalVisible,
        setSearchModalActive,
        initSearchOptions,
        searchOptions,
        selectedButtons
    } = useContext(GlobalContext);
    const [tabName, setTabName] = useState<any[]>([]);
    const prevAreaId = usePrevious(areaId);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const getSearchSettings = async () => {
        const tabNameArray: string[] = [];
        const searchSettingTabSettingsDatas: any[] = [];
        const viewableContestIds: number[] = [];

        await axios
            .get(`/contests?areaId=${areaId}&mallId=${currentUser.id}`)
            .then((res) => {
                res.data.forEach((item: any) => {
                    viewableContestIds.push(item.id);
                });
            })
            .catch((error: any) => {
                console.log(error);
            });

        await axios
            .get(`/searchSettings/${areaId}`)
            .then((res) => {
                if (res.data.tabSettings) {
                    res.data.tabSettings?.forEach((item: any) => {
                        if (item.contestId && !viewableContestIds.includes(item.contestId)) return;
                        tabNameArray.push(item.tabName);
                        searchSettingTabSettingsDatas.push(item);
                    });

                    // 表示可能なdata.tabSettingsに差し替え
                    res.data.tabSettings = searchSettingTabSettingsDatas;

                    setTabName(tabNameArray);
                    setSearchSettings(res.data);
                } else {
                    setTabName([]);
                    setSearchSettings({});
                }
            })
            .catch((error: any) => {
                setTabName([]);
                setSearchSettings({});
                console.log(error);
            });
    };

    const onPressTab = (index: number) => {
        setSelected(index);
        setKeyword('');
        setInputWord('');
        initSearchOptions();
        navigate('/posts');
    };

    // 初回レンダリング時およびareaId変更時の処理
    useEffect(() => {
        if (areaId) {
            // スクロールをトップへ移動
            goToTop();
            // 検索設定（投稿一覧のタブなどの設定）を取得
            getSearchSettings();
            // 一つ目のタブに戻す
            setSelected(0);
            // エリアを切り替えた時に、フリー検索をリセット（初回リンダリング時は初期化しない）
            if (prevAreaId !== null) {
                setKeyword('');
                setInputWord('');
                initSearchOptions();
                navigate('/posts');
            }
        }
    }, [areaId]);

    useEffect(() => {
        // キーワード変更時にスクロールをトップへ移動
        goToTop();
    }, [keyword]);

    useEffect(() => {
        if (!searchParams.get('contestName')) return;
        setKeyword(`contestName: ${searchParams.get('contestName')}`);
        setInputWord(`contestName: ${searchParams.get('contestName')}`);
        setSearchModalVisible(true);
        setSearchModalActive(false);
    }, [searchParams]);

    return (
        <View>
            {currentUser ? <UserNameBar mallName={currentUser.mallName} /> : null}
            <AreaContestSelect />
            {currentUser ? (
                <SideScroll
                    tabNames={tabName}
                    selected={selected}
                    onPress={onPressTab}
                    keyword={keyword}
                    searchOptions={searchOptions}
                    selectedButtons={selectedButtons}
                />
            ) : (
                <Spinner color={Colors.white} size="sm" width={'fit-content'} paddingRight={50} paddingLeft={50} />
            )}
            {currentUser ? (
                <ImageList searchSettings={searchSettings} selected={selected} />
            ) : (
                <Spinner color={Colors.white} size="sm" width={'fit-content'} paddingRight={50} paddingLeft={50} />
            )}
            <PostsDetailModal />
        </View>
    );
}
