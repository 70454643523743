import { Box } from 'native-base';
import React from 'react';
import { Button, ScrollView, TouchableOpacity, View, StyleSheet, Text, Pressable } from 'react-native';

const RoundButton = (props: any) => {
    const {
        text,
        color,
        borderColor,
        fontColor,
        alignItems,
        onPress,
        disabled,
        backgroundColor,
        width,
        maxWidth,
        minWidth,
        margin,
        marginVertical,
        marginHorizontal,
        paddingTop,
        paddingBottom,
        paddingRight,
        paddingLeft,
        height,
        ...rest
    } = props;
    return (
        <Pressable
            style={{
                paddingTop: paddingTop ?? 13,
                paddingBottom: paddingBottom ?? 13,
                paddingRight: paddingRight ?? 24,
                paddingLeft: paddingLeft ?? 24,
                margin: margin ?? 10,
                marginVertical: marginVertical,
                marginHorizontal: marginHorizontal,
                alignItems: alignItems,
                borderRadius: 50,
                height: height ?? 50,
                width: width,
                maxWidth: maxWidth,
                minWidth: minWidth,
                borderWidth: 2,
                borderColor: borderColor,
                backgroundColor: backgroundColor,
                justifyContent: 'center'
            }}
            disabled={disabled}
            onPress={onPress}
        >
            <Text style={{ color: fontColor, fontWeight: 'bold' }}>{props.text}</Text>
        </Pressable>
    );
};
export default RoundButton;
