import React, { useState, useEffect, useRef, useContext } from 'react';
import { ScrollView } from 'react-native';
export const GlobalContext = React.createContext({} as any);
import axios from '../axios/axios';
import { AuthContext } from './Auth';

export const GlobalProvider = ({ children }: any) => {
    const { currentUser } = useContext(AuthContext);

    const [keyword, setKeyword] = useState('');
    const [inputWord, setInputWord] = useState('');
    const [areaId, setAreaId] = useState(null);
    const [areaName, setAreaName] = useState('');
    const [areas, setAreas] = useState<any[]>([]);
    const [isScrollEnd, setIsScrollEnd] = useState('');
    const scrollViewRef = useRef<ScrollView>(null);
    const [postComplete, setPostComplete] = useState(false);
    const [selectedButtons, setSelectedButtons] = useState<Record<string, boolean>>({});
    const [selectedJobTypes, setSelectedJobTypes] = useState<number[]>([]);
    const [selectedAreaIds, setSelectedAreaIds] = useState<number[]>([]);
    const [selectedMonthIds, setSelectedMonthIds] = useState<number[]>([]);
    const [jobTypesList, setJobTypesList] = useState<number[]>([]);
    const [searchOptions, setSearchOptions] = useState<Map<string, number[]>>(
        new Map([
            ['selectedAreaIds', []],
            ['selectedJobTypes', []],
            ['selectedMonthIds', []]
        ])
    );
    // 検索モーダル表示切り替え用
    const [searchModalVisible, setSearchModalVisible] = useState<boolean>(false);
    const [searchModalActive, setSearchModalActive] = useState(false);

    useEffect(() => {
        if (!currentUser) return;
        console.log('Set currentUser');
        // 初期の地区ID（ログインユーザのareaId）をセット
        setAreaId(currentUser.areaId);

        // 全ての地区と初回の表示地区名をセット
        const apiUrl = `/areas`;

        axios.get(apiUrl).then((res) => {
            const areasData = res.data;
            setAreas(areasData);
            const targetAreaName = areasData.find((area: any) => area.id == currentUser.areaId).areaName;
            setAreaName(targetAreaName);
        });
    }, [currentUser]);

    function initSearchOptions() {
        setSelectedAreaIds([]);
        setSelectedJobTypes([]);
        setSelectedMonthIds([]);
        setSearchOptions(
            new Map([
                ['selectedAreaIds', []],
                ['selectedJobTypes', []],
                ['selectedMonthIds', []]
            ])
        );
        setSelectedButtons({});
    }

    return (
        <GlobalContext.Provider
            value={{
                keyword,
                setKeyword,
                inputWord,
                setInputWord,
                areaId,
                setAreaId,
                areaName,
                setAreaName,
                areas,
                setAreas,
                jobTypesList,
                selectedJobTypes,
                setSelectedJobTypes,
                selectedAreaIds,
                setSelectedAreaIds,
                selectedMonthIds,
                setSelectedMonthIds,
                isScrollEnd,
                setIsScrollEnd,
                scrollViewRef,
                searchModalVisible,
                setSearchModalVisible,
                searchModalActive,
                setSearchModalActive,
                postComplete,
                setPostComplete,
                selectedButtons,
                setSelectedButtons,
                searchOptions,
                setSearchOptions,
                initSearchOptions
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};
