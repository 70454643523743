import { StatusBar } from 'expo-status-bar';
import React, { useContext, useEffect, useState, ErrorInfo, ReactNode } from 'react';
import { StyleSheet, Text, View, Image, TextInput, Button, TouchableOpacity, ScrollView } from 'react-native';
import { withSafeAreaInsets } from 'react-native-safe-area-context';
import { AuthContext } from '../contexts/Auth';
import { useNavigate } from 'react-router-dom';
import { LinearGradient } from 'expo-linear-gradient';
import { Box, HStack, Spinner } from 'native-base';
import { Consts } from '../constants/Consts';
import { flexbox } from 'native-base/lib/typescript/theme/styled-system';
import { useThemeColors, useThemeImages, useThemedComponents, useThemedAjusts } from '../components/Themed';
import { ColorNames, ImageNames, ThemedComponentType, ThemedAjustType } from '../types';

export default function App() {
    const Colors: ColorNames = useThemeColors();
    const Images: ImageNames = useThemeImages();
    const ThemedComponent: ThemedComponentType = useThemedComponents();
    const Ajusts: ThemedAjustType = useThemedAjusts();

    const [userId, setUserId] = useState('');
    const [loading, setLoading] = useState(false);
    const { isAuth, login, loginErrorMessage } = useContext(AuthContext);
    const navigate = useNavigate();

    //クエリパラメータログインチェック
    const url = new URL(window.location.href);
    const id_value = url.searchParams.get('id');
    React.useEffect(() => {
        if (isAuth) {
            setLoading(false);
            navigate('/posts');
        }
    }, [isAuth]);

    React.useEffect(() => {
        if (id_value) {
            login(id_value);
        }
    }, [id_value]);

    const onPressLogin = () => {
        if (!loading) {
            login(userId);
            setLoading(true);
        }
    };

    React.useEffect(() => {
        if (loginErrorMessage) {
            setLoading(false);
        }
    }, [loginErrorMessage]);

    const styles = StyleSheet.create({
        scroll: {
            backgroundColor: Colors.white,
            paddingRight: 20,
            paddingLeft: 20
        },
        container: {
            alignItems: 'center',
            justifyContent: 'center'
        },
        image: {
            marginBottom: 52,
            width: '275px',
            height: '96px'
        },
        loginTitleBox: {
            width: '25%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: Colors.lightGray,
            borderColor: Colors.mediumGray,
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomLeftRadius: 50,
            borderTopLeftRadius: 50
        },
        loginTitle: {
            fontSize: 12,
            fontWeight: '600'
        },
        loginTextBox: {
            height: '100%',
            width: '75%',
            justifyContent: 'center',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderColor: Colors.mediumGray,
            borderBottomRightRadius: 50,
            borderTopRightRadius: 50
        },
        loginTextInput: {
            height: '100%',
            padding: 10,
            borderBottomRightRadius: 50,
            borderTopRightRadius: 50
        },
        loginButtonText: {
            color: Colors.white,
            fontWeight: 'bold'
        },
        loginButton: {
            maxWidth: '335px',
            width: '100%',
            borderRadius: 50,
            height: 50,
            alignItems: 'center',
            justifyContent: 'center'
        },
        errorText: {
            color: Colors.error,
            marginTop: 10
        },
        applicationTextBox: {
            paddingTop: '20px'
        },
        youtube: {
            marginTop: '10px',
            display: Ajusts.Display
        },
        applicationText: {
            textAlign: 'center',
            fontSize: 18,
            fontWeight: '800'
        },
        titleTxt: {
            fontWeight: 'bold',
            color: Colors.primary,
            fontSize: 20,
            paddingTop: '20px',
            textAlign: 'center'
        },
        titlePeriod: {
            fontSize: Ajusts.LoginPeriodFontSize,
            paddingTop: '20px'
        }
    });

    return (
        <ScrollView contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }} style={styles.scroll}>
            <View style={styles.container}>
                <ThemedComponent.LoginImage />

                {/* <StatusBar style="auto" /> */}

                <HStack flexDirection="row" marginBottom={15} maxWidth="335px" width="100%" height="46px">
                    <Box style={styles.loginTitleBox}>
                        <Text style={styles.loginTitle}>ログインID</Text>
                    </Box>
                    <Box style={styles.loginTextBox}>
                        <TextInput
                            style={styles.loginTextInput}
                            placeholder="IDを入力してください"
                            placeholderTextColor={Colors.lightGray}
                            secureTextEntry={false}
                            onChangeText={(userId) => setUserId(userId)}
                        />
                    </Box>
                </HStack>

                <TouchableOpacity disabled={loading} onPress={() => onPressLogin()} style={styles.loginButton}>
                    <LinearGradient
                        colors={
                            !loading
                                ? [Colors.primaryGradientStart, Colors.primaryGradientEnd]
                                : [Colors.lightGray, Colors.lightGray]
                        }
                        start={[0, 0]}
                        end={[1, 0]}
                        style={styles.loginButton}
                    >
                        {loading ? (
                            <Spinner color={Colors.primary} />
                        ) : (
                            <Text style={styles.loginButtonText}>ログインする</Text>
                        )}
                    </LinearGradient>
                </TouchableOpacity>
                {loginErrorMessage ? <Text style={styles.errorText}>{loginErrorMessage}</Text> : null}
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <Box style={styles.youtube}>
                        <video
                            width="200px"
                            controls
                            preload="none"
                            controlsList="nodownload"
                            poster={require('../assets/images/skillup202503.jpg')}
                            src="https://firebasestorage.googleapis.com/v0/b/am-vp-contest-site-staging.appspot.com/o/recommend%2FVP%E3%82%B9%E3%82%AD%E3%83%AB%E3%82%A2%E3%83%83%E3%83%97%E4%BC%81%E7%94%BB%203%E6%9C%88%E7%89%88%20250220.mp4?alt=media&token=f67ca985-3f2f-4bfd-8c82-2fb6ed41d487"
                        ></video>
                    </Box>
                    <Box style={styles.youtube}>
                        <iframe
                            width="200px"
                            src="https://www.youtube-nocookie.com/embed/FjYVYaTY_mg?si=yYcuK9LkCestTyhB&rel=0"
                            title="コンテストサイト検索強化"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                        ></iframe>
                    </Box>
                </View>
                {Ajusts.LoginTitle ? <Text style={styles.titleTxt}>{Ajusts.LoginTitle}</Text> : null}
                {Ajusts.LoginPeriod ? <Text style={styles.titlePeriod}>{Ajusts.LoginPeriod}</Text> : null}
            </View>
        </ScrollView>
    );
}
