import { Box, HStack, Image, ScrollView } from 'native-base';
import React, { useState } from 'react';
import { View, Text, StyleSheet, Pressable, Dimensions } from 'react-native';
import FontSize from '../../../constants/FontSize';
import TopBar from '../Bar/TopBar';
import SearchCloseButton from '../ButtonNative/SearchCloseButton';
import SearchInput from './SearchInput';
import SearchOptions from '../../../components/SearchOptions';
import { useThemeColors, useThemedAjusts } from '../../../components/Themed';
import { ColorNames, ThemedAjustType } from '../../../types';

const Colors: ColorNames = useThemeColors();
const Ajusts: ThemedAjustType = useThemedAjusts();
const searchOptionsMaxHeight = Dimensions.get('window').height - Ajusts.HeaderHeight - Ajusts.SearchHeaderHeight - 15;

type Suggestion = {
    id: any;
    name: string;
};

const SearchArea = (props: any) => {
    const { suggestions, onPress, onChangeText, value, onPressSearch, active, setActive, ...rest } = props;

    return (
        <HStack
            bg={Colors.lightestGray}
            alignItems="center"
            flexDirection="column"
            maxHeight="100vh"
            width="100%"
            paddingLeft={15}
            paddingRight={15}
            overflow="hidden"
        >
            <Box maxWidth="600px" width="100%" paddingTop={4} paddingBottom={4}>
                <SearchInput
                    value={value}
                    onChangeText={onChangeText}
                    onPressSearch={onPressSearch}
                    active={active}
                    setActive={setActive}
                />
                <ScrollView style={{ maxHeight: searchOptionsMaxHeight, paddingBottom: 15 }}>
                    {active && suggestions.length > 0 ? (
                        Ajusts.SearchOptionsVisible ? (
                            <ScrollView maxHeight={150}>
                                <Box
                                    width="100%"
                                    alignItems="center"
                                    marginTop={Ajusts.SearchOptionsVisible ? 0 : '-10px'}
                                    marginBottom="15px"
                                    paddingLeft={15}
                                    paddingRight={15}
                                >
                                    {suggestions.map((data: Suggestion, index: number) => {
                                        return (
                                            <Pressable
                                                key={data.id}
                                                style={{
                                                    width: '100%',
                                                    alignItems: 'center'
                                                }}
                                                onPress={() => onPress(data.name)}
                                            >
                                                <Box
                                                    flexDirection="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    width="100%"
                                                    paddingTop="9px"
                                                    paddingBottom="9px"
                                                    minHeight={53}
                                                >
                                                    <Text
                                                        style={{
                                                            color: `${Colors.primary}`,
                                                            fontSize: FontSize.medium,
                                                            fontWeight: '400'
                                                        }}
                                                    >
                                                        #{data.name}
                                                    </Text>
                                                    <Image
                                                        height="13.33px"
                                                        width="7.85px"
                                                        source={require('../../../assets/images/detail-gray.svg')}
                                                        alt=">"
                                                    />
                                                </Box>
                                                <View
                                                    style={{
                                                        borderBottomWidth: 1,
                                                        borderStyle: 'dashed',
                                                        width: '100%',
                                                        borderColor: Colors.lightGray
                                                    }}
                                                />
                                            </Pressable>
                                        );
                                    })}
                                </Box>
                            </ScrollView>
                        ) : (
                            <Box
                                width="100%"
                                alignItems="center"
                                marginTop={Ajusts.SearchOptionsVisible ? 0 : '-10px'}
                                marginBottom="15px"
                                paddingLeft={15}
                                paddingRight={15}
                            >
                                {suggestions.map((data: Suggestion, index: number) => {
                                    return (
                                        <Pressable
                                            key={data.id}
                                            style={{
                                                width: '100%',
                                                alignItems: 'center'
                                            }}
                                            onPress={() => onPress(data.name)}
                                        >
                                            <Box
                                                flexDirection="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                                paddingTop="9px"
                                                paddingBottom="9px"
                                                minHeight={53}
                                            >
                                                <Text
                                                    style={{
                                                        color: `${Colors.primary}`,
                                                        fontSize: FontSize.medium,
                                                        fontWeight: '400'
                                                    }}
                                                >
                                                    #{data.name}
                                                </Text>
                                                <Image
                                                    height="13.33px"
                                                    width="7.85px"
                                                    source={require('../../../assets/images/detail-gray.svg')}
                                                    alt=">"
                                                />
                                            </Box>
                                            <View
                                                style={{
                                                    borderBottomWidth: 1,
                                                    borderStyle: 'dashed',
                                                    width: '100%',
                                                    borderColor: Colors.lightGray
                                                }}
                                            />
                                        </Pressable>
                                    );
                                })}
                            </Box>
                        )
                    ) : null}
                    {Ajusts.SearchOptionsVisible ? <SearchOptions /> : null}
                </ScrollView>
            </Box>
        </HStack>
    );
};

export default SearchArea;
