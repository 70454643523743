import { Box, Center, HStack, Image, Link } from 'native-base';
import React, { useContext, useState } from 'react';
import { Pressable, View, Text, ScrollView } from 'react-native';
import { useNavigate } from '../../../router/react-router';
import { GlobalContext } from '../../../contexts/Global';
import { useThemeColors } from '../../../components/Themed';
import { ColorNames } from '../../../types';
import _ from 'lodash';

const Colors: ColorNames = useThemeColors();

function isSearchOptionsEmpty(map: Map<string, number[]>): boolean {
    return _.every(Array.from(map.values()), (value) => _.isEmpty(value));
}

const SideScroll = (props: {
    tabNames: string[];
    selected: number;
    keyword: string;
    searchOptions: Map<string, number[]>;
    selectedButtons: object;
    onPress: (props: any) => void;
}) => {
    const { tabNames, selected, keyword, onPress, searchOptions } = props;

    return (
        <View style={{ flex: 1, paddingTop: '13px', marginBottom: '0px' }}>
            <View
                style={{
                    width: '100%',
                    height: '42px',
                    borderBottomColor: Colors.lightGray,
                    borderBottomWidth: 1,
                    position: 'absolute'
                }}
            />
            <ScrollView
                style={{
                    paddingLeft: '14px',
                    paddingRight: '14px'
                }}
                horizontal={true}
                showsVerticalScrollIndicator={false}
            >
                {tabNames.map((name: any, index: number) => {
                    return (
                        <Pressable
                            style={{
                                height: selected === index ? '42px' : '40px',
                                marginRight: '6px'
                            }}
                            onPress={() => onPress(index)}
                            key={index}
                        >
                            {selected === index && !keyword && isSearchOptionsEmpty(searchOptions) ? (
                                <View
                                    style={{
                                        borderWidth: 2,
                                        borderColor: Colors.primary
                                    }}
                                />
                            ) : (
                                <View
                                    style={{
                                        borderWidth: 2,
                                        borderColor: Colors.lightGray
                                    }}
                                />
                            )}
                            <Center
                                px={'20px'}
                                borderRightWidth={1}
                                borderLeftWidth={1}
                                borderColor={Colors.lightGray}
                                paddingTop="9px"
                                paddingBottom={'15px'}
                                paddingLeft="10px"
                                paddingRight="10px"
                                {...(selected === index && !keyword && isSearchOptionsEmpty(searchOptions)
                                    ? {
                                          backgroundColor: Colors.white
                                      }
                                    : {
                                          backgroundColor: Colors.lightGray
                                      })}
                                opacity={
                                    selected === index && !keyword && isSearchOptionsEmpty(searchOptions) ? 1 : 0.3
                                }
                            >
                                <Text
                                    style={[
                                        selected === index && !keyword && isSearchOptionsEmpty(searchOptions)
                                            ? { color: Colors.primary }
                                            : { color: Colors.mediumGray },
                                        { fontWeight: '600' }
                                    ]}
                                >
                                    {name}
                                </Text>
                            </Center>
                        </Pressable>
                    );
                })}
            </ScrollView>
        </View>
    );
};

export default SideScroll;
