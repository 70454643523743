import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Box, Center, Hidden, HStack, Icon, Image, Pressable, Text } from 'native-base';
import React, { Children, useState, useContext } from 'react';
import Hamburger from 'hamburger-react';
import Zindex from '../../../constants/Zindex';
import { GlobalContext } from '../../../contexts/Global';
import { useNavigate } from 'react-router-dom';
import { useThemeColors, useThemeImages, useThemedAjusts } from '../../../components/Themed';
import { ColorNames, ImageNames, ThemedAjustType } from '../../../types';
import FontSize from '../../../constants/FontSize';

const TopBar = (props: any) => {
    const { menuModalVisible, setMenuModalVisible, ...rest } = props;
    const { setKeyword, setInputWord, initSearchOptions } = useContext(GlobalContext);
    const Colors: ColorNames = useThemeColors();
    const Images: ImageNames = useThemeImages();
    const Ajusts: ThemedAjustType = useThemedAjusts();
    const navigate = useNavigate();

    return (
        <HStack
            bg={Colors.white}
            justifyContent="flex-end"
            alignItems="center"
            position="absolute"
            top={0}
            left={0}
            borderBottomColor={Colors.lightestGray}
            borderBottomWidth={1}
            width="100%"
            zIndex={Zindex.header}
            height={Ajusts.HeaderHeight}
        >
            <HStack
                width="100%"
                space={1}
                top={Ajusts.PosTop}
                position="absolute"
                flexDirection="column"
                justifyContent="center"
            >
                <Center>
                    <Pressable
                        onPress={() => {
                            setKeyword('');
                            setInputWord('');
                            initSearchOptions();
                            navigate('/posts');
                        }}
                    >
                        <Image
                            style={{ width: Ajusts.HeaderImgWidth, height: Ajusts.HeaderImgHeight }}
                            source={Images.logo.uri}
                            alt={Images.logo.imgName}
                        />
                    </Pressable>
                </Center>
            </HStack>
            <Pressable left={0} px="10px" position="absolute" onPress={() => setMenuModalVisible(!menuModalVisible)}>
                <Hamburger color={Colors.mediumGray} toggled={menuModalVisible} />
                <Text fontSize={FontSize.xsmall} color={Colors.mediumGray}>
                    メニュー
                </Text>
            </Pressable>
            {props.children}
        </HStack>
    );
};

export default TopBar;
