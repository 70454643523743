import { Box, View, ScrollView } from 'native-base';
import React, { useState, useEffect, useContext } from 'react';
import { Text, StyleSheet } from 'react-native';
import { useThemeColors } from '../components/Themed';
import { ColorNames } from '../types';
import RoundButton from '../stories/components/ButtonNative/RoundButton';
import { GlobalContext } from '../contexts/Global';
import axios from '../axios/axios';
import Accordion from '../stories/components/Drawer/Accordion';

const Colors: ColorNames = useThemeColors();

interface CategoryWithButtonsProps {
    category: string;
    buttons: string[];
}

const SearchOptions = () => {
    const { areas, selectedButtons, setSelectedButtons, setSelectedJobTypes, setSelectedAreaIds, setSelectedMonthIds } =
        useContext(GlobalContext);

    // jobType取得
    const [jobTypeList, setJobTypeList] = useState<any[]>([]);
    const getJobTypes = async () => {
        const jobTypeGetUrl = `/jobtypes?_sort=id`;
        const returnList: Array<any> = [];
        try {
            const result = await axios.get(jobTypeGetUrl);
            const jobTypesList: Array<any> | undefined = result.data;
            if (jobTypesList && jobTypesList.length > 0) {
                jobTypesList
                    .sort((a, b) => a.id - b.id)
                    .forEach((jobtype: any) => {
                        returnList.push({ value: jobtype.id, label: jobtype.name });
                    });
            } else {
                console.log('jobTypeList Undefined');
            }
            setJobTypeList(returnList);
        } catch (error) {
            console.log('通信失敗', error);
        }
    };
    useEffect(() => {
        getJobTypes();
    }, []);

    // カテゴリとボタンの配列定義
    const categoriesWithButtons: CategoryWithButtonsProps[] = [
        {
            category: 'エリア',
            buttons: areas.map((area: any) => area.areaName)
        },
        {
            category: '業種',
            buttons: jobTypeList.map((job: any) => job.label)
        },
        {
            category: '月',
            buttons: Array.from({ length: 12 }, (_, i) => `${i + 1}月`)
        }
    ];

    // 押したボタンからカテゴリに対するIDを取得する(index番目のid)
    const handlePress = (category: string, index: number) => {
        const categoryIndex = ['エリア', '業種', '月'].indexOf(category);
        if (categoryIndex === -1) return;

        let selectedId = -1;

        if (category === 'エリア') {
            selectedId = areas?.[index]?.id ?? -1;
        } else if (category === '業種') {
            selectedId = jobTypeList?.[index]?.value ?? -1;
        } else if (category === '月') {
            selectedId = index + 1;
        }

        if (selectedId === -1) {
            console.error('Invalid selectedId', category, index);
            return;
        }

        // buttonトグル処理
        setSelectedButtons((prevButtons: any) => ({
            ...prevButtons,
            [`${category}-${index}`]: !prevButtons[`${category}-${index}`]
        }));
        // 選択されたID状態を更新
        if (category === 'エリア') {
            setSelectedAreaIds((prev: any) =>
                (prev ?? []).includes(selectedId)
                    ? prev.filter((id: any) => id !== selectedId)
                    : [...(prev ?? []), selectedId]
            );
        } else if (category === '業種') {
            setSelectedJobTypes((prev: any) =>
                (prev ?? []).includes(selectedId)
                    ? prev.filter((id: any) => id !== selectedId)
                    : [...(prev ?? []), selectedId]
            );
        } else if (category === '月') {
            setSelectedMonthIds((prev: any) =>
                (prev ?? []).includes(selectedId)
                    ? prev.filter((id: any) => id !== selectedId)
                    : [...(prev ?? []), selectedId]
            );
        }
    };

    return (
        <Box style={styles.optionContainer}>
            {/* <ScrollView style={styles.scrollContainer}> */}
            <View style={styles.gapGroup}>
                {categoriesWithButtons.map((item, index) => (
                    <Box key={index} style={styles.optionGroup}>
                        <Text style={styles.category}>{item.category}</Text>
                        <View style={styles.buttonGroup}>
                            {item.buttons.map((buttonText, buttonIndex) => (
                                <RoundButton
                                    key={buttonIndex}
                                    text={buttonText}
                                    onPress={() => handlePress(item.category, buttonIndex)}
                                    minWidth={100}
                                    height={42}
                                    paddingTop={2}
                                    paddingBottom={2}
                                    paddingRight={16}
                                    paddingLeft={16}
                                    backgroundColor={
                                        selectedButtons[`${item.category}-${buttonIndex}`]
                                            ? Colors.primary
                                            : Colors.lightGray
                                    }
                                    borderColor="transparent"
                                    fontColor={Colors.white}
                                    alignItems="center"
                                    margin={0}
                                />
                            ))}
                        </View>
                    </Box>
                ))}
            </View>
            {/* </ScrollView> */}
        </Box>
    );
};

const styles = StyleSheet.create({
    optionContainer: {
        justifyContent: 'flex-start',
        flexDirection: 'column',
        height: 'auto',
        width: '100%',
        marginTop: 12,
        marginBottom: 12,
        paddingRight: 4,
        paddingLeft: 4
    },
    scrollContainer: {
        maxHeight: 540
    },
    gapGroup: {
        flexDirection: 'column',
        gap: 24
    },
    category: {
        color: Colors.mediumGray,
        fontWeight: 'bold'
    },
    buttonGroup: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16,
        gap: 12
    },
    optionGroup: {
        marginTop: 0,
        marginBottom: 12
    }
});

export default SearchOptions;
